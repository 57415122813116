import { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalFooter,
         ModalBody, Button, Label, FormGroup,
         Form, Row, Col, } from 'reactstrap';
import { getCanteenRecipes } from 'services/api';
import { handleAxiosError } from 'utils/utils';
import Select from 'react-select';
import { strings } from 'localization';
import { getAssignableRecipes } from 'services/api';

export default function ReassignRecipeModal(props) {
    const [recipes, setRecipes] = useState([]);
    const [selectedRecipe, setSelectedRecipe] = useState(null);


    useEffect(() => {
        async function fetchRecipes() {
            try {
                let responsePromise = null;
                
                if (props.recipeFilters) {
                    if (props.recipeFilters.useAssignableRecipes) {
                        responsePromise = getAssignableRecipes(props.location, props.recipeFilters.timestamp);
                    } else {
                        responsePromise = getCanteenRecipes(props.location, props.recipeFilters.timestamp, props.recipeFilters.mode);
                    }
                } else {
                    responsePromise = getCanteenRecipes(props.location);
                }
                
                const response = await responsePromise;

                setRecipes(response.data);
            } catch (error) {
                handleAxiosError(error, {}, strings.reassignRecipeModal__toastError__genericApiError);
            }
        }

        fetchRecipes();
    }, [setRecipes, props.location, props.recipeFilters]);

    useEffect(() => {
        if (props.isOpen) {
            if (props.defaultSelected) {
                let recipe = recipes.filter((r) => r.value === props.defaultSelected);
                if (recipe.length > 0) {
                    recipe = recipe[0];
                    setSelectedRecipe(recipe);
                }
            }
        }
    }, [props.isOpen, props.defaultSelected, recipes])

    return (
        <Modal isOpen={props.isOpen && recipes.length > 0} backdrop={true} toggle={props.toggle}>
            <ModalHeader toggle={props.toggle}>
                {strings.reassignRecipeModal__title}
            </ModalHeader>
            <ModalBody>
                <Form>
                    <Row>
                        <Col md="12">
                            <FormGroup>
                            <Label for='recipeSelect'>{strings.reassignRecipeModal__dishFieldLabel}</Label>
                            <Select
                                id='recipeSelect'
                                value={selectedRecipe}
                                onChange={(l) => setSelectedRecipe(l)}
                                options={recipes}
                                placeholder={strings.reassignRecipeModal__dishFieldPlaceholder}
                            />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" disabled={!selectedRecipe} onClick={() => props.onReassign(selectedRecipe)}>
                    {strings.reassignRecipeModal__confirmButtonLabel}
                </Button>
                <Button color="secondary" onClick={props.toggle}>
                    {strings.reassignRecipeModal__cancelButtonLabel}
                </Button>
            </ModalFooter>
        </Modal>
    );
}
